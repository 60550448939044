:root {
  --electricBlue: rgb(8, 146, 208);
  --darkBlue: #16242f;
  --navyBlue: #1d2951;
  --red: rgb(182, 6, 2);
  --lightBlue: #eaf8ff;
  --lightBluergb: 234, 248, 255;
  --buttonBlue: #085ed8;
  --graySelect: #D7D7D7;
}


.pageLayout {
  margin: 2rem 2rem 2rem 2rem;

}