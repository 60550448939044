.socialProducts{
    display: flex;
    flex-direction: row;
}

.assistiveTech{
    margin-bottom: 50px;
}

.socialProducts > .soP{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.socialProducts  h5{
    text-align: center;
}

  
.comingSoon{
    display: flex;
justify-content: center;
    align-items: center;

    height: 486px;
    border: dotted;
}

@media screen and (max-width: 768px){
    .assistiveTech{
        text-align: center;
    }
}