.navbarPositioning {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5rem 1.5rem 1.5rem 1.5rem;
    align-items: center;
  }
  
  .brandLink {
    text-decoration: none;
    color: var(--darkBlue);
  }
  .brandPositioning {
    display: flex;
    transition: all 0.5s;
  }
  /* .brandPositioning:hover {
    transform: scale(1.1);
  } */
  .titleContainer {
    /* width: 167px; */
    margin: auto;
  }
  
  .orgTitle {
    font-size: 18px;
    text-wrap: wrap;
    line-height: 24px;
    font-family: "Futura";
    font-weight: 600;
    text-align: center;
    margin: auto;
    transition: all 0.5s;
  }
  
  .logo {
    width: calc(1.5rem + 5vw);
    /* width: 200px; */
    /* prevents the logo stretching vertically with display flex */
    align-self: center;
    /* max-width: 74px; */
    /* border: solid red 1px; */
    /* margin-left: -35px; */
    /* color: #1b90fa; */
  }
  
  .navbar-toggle {
    border-color: #0e1f2e;
    outline: none;
  }
  
  .navbar-nav {
    display: flex;
    gap: 1rem;
  }
  
  .nav-link {
    font-size: calc(0.4rem + 0.8vw);
    font-weight: bold;
    color: var(--darkBlue);
    text-transform: uppercase;
    transition: all 0.5s;
  }
  
  /* .nav-link:hover {
    transform: scale(1.2);
  } */
  
  @media screen and (max-width: 991px) {
    .navbarPositioning {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
    /* .orgTitle:hover {
      transform: scale(1.1);
    } */
  }
  